<template>
<!-- 云健身-活跃时间段分布 -->
    <div class="sportnav darkBack">
        <div>活跃时间段分布</div>
        <div id="activeTime"></div>
    </div>
</template>

<script>
import Vue from 'vue';
import {timestamp} from "/src/assets/js/websocket";

export default {
    data () {
        return {
            sport: {
                data:[],
                number:[]
            }
        }
    },
    methods: {
        lineOption(id, number){
            // let canye = this.$echarts.init(document.getElementById(id));
            let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById(id));
            }
            var option = {
                xAxis: {
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: '#2D94D0',
                        }
                    },
                    axisLabel: {               // x轴字体的颜色
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: "#7EA7E299",
                            fontSize: '13',
                        },                           
                    },
                    offset: 4.5,      // x轴字体与x轴的距离
                    data: number.data,
                },
                grid:{
                    left:"10%",
                    right:"8%",
                    top:"15%",
                    bottom: "10%"
                },
                yAxis: {
                    type: 'value',
                    splitNumber: 4,         // y轴左侧显示的条数
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: ['#2D94D040'],
                            width: 1,
                            type: 'solid',
                        }
                　　},
                    axisLabel: {               // y轴字体的颜色
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: "#7EA7E299",
                            fontSize: '0.14rem',
                        },                           
                    },
                    // axisLabel: {        // 隐藏y轴数据
                    //     formatter: function () {
                    //         return "";
                    //     }
                    // },
                },
                series: [{
                    data: number.number,
                    symbol: 'circle',//拐点样式
                    symbolSize: 9,   //拐点圆的大小
                    type: 'line',
                    itemStyle : { 
                        normal : { 
                            color:'#3AE6CE', //改变折线点的颜色
                            lineStyle:{ 
                                color:'#3AE6CE' //改变折线颜色
                            } 
                        } 
                    }, 
                }]
            };
            canye.setOption(option);
			// 图表自动缩放
			window.addEventListener("resize",function(){
			  canye.resize();
			});
        },
        // 获取数据
        getData(){
            let data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
            let url;
            if(info.is_city == 1) url = window.appHost+"/api/v1/screen/Cloud_Gym/active_time"
            else url = window.appHost+"/api/v1/screen/Cloud_Gym/active_time?area="+info.name

            let result = window.ajaxRequest(url,JSON.stringify(data),"post")
            // console.log(url)
            // console.log(result)
            this.sport = {
                    data:[],
                    number:[]
                };
            for(let i in result.data){
                // console.log(i,result.data[i])
                this.sport.data.push(i);
                this.sport.number.push(result.data[i]);
            }
            this.lineOption("activeTime", this.sport)
        }
    },
    mounted () {
        this.getData();
        
    },
    created () {

    }
}
</script>

<style scoped>
.sportnav{
    width:5.96rem;
    height:3.30rem;
    color:#FFFFFF;
    padding:0.2rem;
    font-size: 0.2rem;
    position: relative;
}
#activeTime{
    width:5.60rem;
    height:2.60rem;
    /* border:1px solid red; */
}
</style>